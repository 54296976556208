<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="fl department_div">
        <el-tree
          class="deptTree"
          default-expand-all
          node-key="id"
          :data="deptList"
          :props="defaultProps"
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
          :render-content="renderContent"
          :highlight-current="true"
        >
        </el-tree>
      </div>
      <div class="table_right">
        <div class="mainList_operation clearfloat">
          <!-- 搜索栏 -->
          <div class="mainList_operation_inline_block">
            <div class="mainList_operation_search">
              <span
                >姓名：
                <el-input
                  class="search_input"
                  v-model="parameter.userName"
                  clearable
                  placeholder="请输入姓名"
                />
              </span>
              <span
                >标签：
                <el-input
                  class="search_input"
                  v-model="parameter.labelName"
                  clearable
                  placeholder="请输入标签信息"
                />
              </span>
              <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)">
                搜索
              </el-button>
              <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
              <el-button type="primary" icon="el-icon-download" @click="exportFn">导出</el-button>
              <el-button
                v-if="permission(['USER_BATCH_IMPORT'])"
                type="primary"
                icon="el-icon-upload2"
                style="width: 105px"
                @click="handleImport"
                >人员导入</el-button
              >
              <el-button
                v-if="permission(['USER_ZJEBJ'])"
                type="primary"
                icon="el-icon-upload2"
                style="width: 105px"
                @click="handleHourlyWageBatch"
                >薪资导入</el-button
              >
            </div>
          </div>
          <!-- 新增|导出|导入 按钮-->
          <div class="fr">
            <el-button type="primary" icon="el-icon-plus" @click="showEditDialog = true"
              >新增</el-button
            >
          </div>
        </div>
        <!-- 数据展示 -->
        <div class="mainList_content" v-show="!showEditDialog">
          <div class="mainList_content_sub">
            <div class="user_list_div" v-loading="loading">
              <div class="staff_div" v-for="(i, k) in tableData">
                <div class="head_div" @click="edit(i)">
                  <img class="pircate_d" v-if="i.picturePath" :src="i.picturePath" alt="" />
                  <img class="pircate_d" v-else src="@/assets/images/portrait.png" alt="" />
                </div>
                <div class="staff_text">{{ i.userName }}</div>
                <div class="staff_text">{{ i.postName }}</div>
              </div>
            </div>
            <!-- 分页 -->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="pageChangeHandler"
              :current-page="parameter.pageNow"
              :page-size="parameter.pageSize"
              prev-text="上一页"
              next-text="下一页"
              layout="total, prev, pager, next, slot, jumper"
              :total="total"
            >
              <span class="el-pagination__jump e_a_pagination">
                <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
                <span style="padding-top: 1px">条/页</span>
              </span>
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 批量用户导入对话框 -->
    <el-dialog title="新增用户导入" :visible.sync="upload.open" width="400px" append-to-body>
      <el-upload
        ref="upload"
        :limit="1"
        accept=".xlsx, .xls"
        action=""
        :http-request="httpRequest"
        :disabled="upload.isUploading"
        :on-progress="handleFileUploadProgress"
        :on-success="handleFileSuccess"
        :auto-upload="false"
        drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip" style="text-align: center">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <a
            style="color: #409eff; border-bottom: 1px solid #409eff"
            href="https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/template/新增用户模板.xlsx"
            >下载模板</a
          >
          <!-- <el-link
            type="primary"
            :underline="false"
            style="font-size: 12px; vertical-align: baseline"
            @click="importTemplate"
            >下载模板</el-link
          > -->
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">确 定</el-button>
        <el-button @click="upload.open = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 批量用户工资总额对话框 -->
    <el-dialog
      title="新增用户工资总额"
      :visible.sync="upload.HourlyWageBatch"
      width="400px"
      append-to-body
    >
      <el-upload
        ref="HourlyWageBatch"
        :limit="1"
        accept=".xlsx, .xls"
        action=""
        :http-request="httpRequestUpdateSalaryBatch"
        :disabled="upload.isUploadingHourlyWageBatch"
        :on-progress="handleFileUploadProgressHourlyWageBatch"
        :on-success="handleFileSuccessHourlyWageBatch"
        :auto-upload="false"
        drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip" style="text-align: center">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <a
            style="color: #409eff; border-bottom: 1px solid #409eff"
            href="https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/template/更新用户薪资模板.xlsx"
            >下载模板</a
          >
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitHourlyWageBatch">确 定</el-button>
        <el-button @click="upload.HourlyWageBatch = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 详情/编辑页面 -->
    <Edit :options="form" :isShow.sync="showEditDialog" v-if="showEditDialog"></Edit>
  </div>
</template>
<script>
import { initTreeData } from '@/util/common'
import { mapState } from 'vuex'

export default {
  components: {
    Edit: () => import('@/views/system/user/Edit.vue'), //详情/编辑页面
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      parameter: {
        //搜索
        userName: '',
        pageNow: 1,
        pageSize: 50,
      },
      total: 0, //人员人数
      form: {}, //详情页放个人信息
      tableData: [], //人员列表
      loading: false, // 人员列表加载
      showEditDialog: false, //详情页面
      deptList: [], // 部门 树形列表
      defaultProps: {
        children: 'children',
        label: 'deptName',
      }, // 部门 树形对象 结构
      pageSize: 0,
      upload: {
        open: false, // 是否显示弹出层
        isUploading: false, // 是否禁用上传
        HourlyWageBatch: false,
        isUploadingHourlyWageBatch: false,
      },

      renderContent: function (h, { node, data, store }) {
        let addElement = arguments[0]
        if (data.level == 1) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconcompany iconDept' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 2) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconcangku iconDept' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 3) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconconnections iconDept' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 4) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconCustomermanagement iconDept' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        }
      },
    }
  },

  watch: {
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  provide() {
    return {
      re: this.re,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getDeptList()
    this.getData()
  },
  methods: {
    /** 加载 "用户" 列表数据 **/
    getData() {
      this.loading = true
      this.$api.user
        .listStaff(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = res.data.records
          this.total = ~~res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /**
     * 点击 "部门" 树形列表
     * @param data      点击获取数据
     */
    handleNodeClick(data) {
      this.parameter.pageNow = 1
      this.parameter.deptId = data.id
      this.getData()
    },

    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 跳转详情/编辑页
    edit(row) {
      if (!row.labelList) row.labelList = []
      this.form = row
      this.showEditDialog = true
    },

    //回到该页面
    re() {
      this.showEditDialog = false
      this.form = {}
      this.getData()
    },

    //导出
    exportFn() {
      this.$api.user
        .exportBatch()
        .then(res => {
          var blob = new Blob([res], { type: 'application/vnd.ms-excel' })
          var url = URL.createObjectURL(blob)
          var exportLink = document.createElement('a')
          exportLink.setAttribute('download', '人员列表.xlsx')
          exportLink.href = url
          document.body.appendChild(exportLink)
          exportLink.click()
          document.body.removeChild(exportLink) //下载完成移除元素
          URL.revokeObjectURL(exportLink.href) //释放掉blob对象
          this.$message({
            message: '导出成功!',
            type: 'success',
          })
        })
        .catch(err => {
          console.log(err)
        })
    },

    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.userName = ''
      this.parameter.labelName = ''
      this.pageChangeHandler(1)
    },

    /** 导入按钮操作 */
    handleImport() {
      this.upload.open = true
    },

    /** 导入按钮操作 */
    handleHourlyWageBatch() {
      this.upload.HourlyWageBatch = true
    },

    // 导出模板
    importTemplate() {
      this.$api.user
        .addTemplate()
        .then(res => {
          console.log(res)
          var blob = new Blob([res], { type: 'application/vnd.ms-excel' })
          var url = URL.createObjectURL(blob)
          var exportLink = document.createElement('a')
          exportLink.setAttribute('download', '新增用户模板.xlsx')
          exportLink.href = url
          document.body.appendChild(exportLink)
          exportLink.click()
          document.body.removeChild(exportLink) //下载完成移除元素
          URL.revokeObjectURL(exportLink.href) //释放掉blob对象
          this.$message({
            message: '导出成功!',
            type: 'success',
          })
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true
    },

    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false
      this.upload.isUploading = false
    },

    // 文件上传中处理
    handleFileUploadProgressHourlyWageBatch(event, file, fileList) {
      this.upload.isUploadingHourlyWageBatch = true
    },

    // 文件上传成功处理
    handleFileSuccessHourlyWageBatch(response, file, fileList) {
      this.upload.HourlyWageBatch = false
      this.upload.isUploadingHourlyWageBatch = false
    },
    // 自定义上传行为,覆盖原组件行为
    httpRequest(params) {
      let fileObj = params.file
      let fd = new FormData()
      fd.append('multipartFile', fileObj)
      console.log(fd)
      this.$api.user
        .addBatch(fd)
        .then(response => {
          this.$alert(
            "<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px;margin-top: -30px;'>" +
              response.data +
              '</div>',
            '导入结果',
            { dangerouslyUseHTMLString: true }
          )
          this.getData()
          this.upload.open = false
        })
        .catch(err => {
          console.log(err)
        })
      this.$refs.upload.clearFiles() // 重置上传表单
    },
    // 自定义上传行为,覆盖原组件行为
    httpRequestUpdateSalaryBatch(params) {
      let fileObj = params.file
      let fd = new FormData()
      fd.append('multipartFile', fileObj)
      console.log(fd)
      this.$api.user
        .updateSalaryBatch(fd)
        .then(response => {
          this.$alert(
            "<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px;margin-top: -30px;'>" +
              response.data +
              '</div>',
            '导入结果',
            { dangerouslyUseHTMLString: true }
          )
          this.getData()
          this.upload.isUploadingHourlyWageBatch = false
        })
        .catch(err => {
          console.log(err)
        })
      // this.$refs.HourlyWageBatch.clearFiles() // 重置上传表单
      // this.upload.HourlyWageBatch = false
    },

    // 提交上传文件
    submitFileForm() {
      this.$refs.upload.submit()
      this.upload.open = false
    },
    // 提交上传文件
    submitHourlyWageBatch() {
      this.$refs.HourlyWageBatch.submit()
      this.$refs?.HourlyWageBatch.clearFiles()
      this.upload.HourlyWageBatch = false
    },

    //换页
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },

    //换页面大小
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.mainList {
  display: block;
}

.department_div {
  width: 256px;
  height: 100%;
  background-color: #ffffff;
  margin-right: 10px;
  padding: 10px 0px;
  overflow: auto;
}

/deep/.el-tree {
  overflow: hidden;
}

.table_right {
  float: right;
  width: calc(100% - 266px);
  height: calc(100% - 90px);
}

.tran_style {
  white-space: nowrap;
  padding: 10px;
}

.mainList .mainList_content .mainList_content_sub .el-pagination {
  text-align: right;
  padding: 0;
}

.mainList .mainList_content .mainList_content_sub {
  padding: 0px;
}

.user_list_div {
  padding: 15px;
  background: #fff;
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;

  .staff_div {
    cursor: pointer;
    align-content: flex-start;
    width: 20%;
    text-align: center;
    padding: 2px 0;

    .head_div {
      .pircate_d {
        width: 100px;
        height: 100px;
      }
    }
  }
}

.search_input {
  width: 165px;
  margin-right: 10px;
}

/deep/.deptName {
  font-size: 14px;
}

/deep/.iconDept {
  color: #399ffb;
}

.deptTree {
  /* 改变被点击节点背景颜色，字体颜色 */
  /deep/ .el-tree-node:focus > .el-tree-node__content {
    background-color: #399ffb !important;
    color: #fff !important;
  }

  /* 改变被点击节点背景颜色，字体颜色 */
  /deep/ .el-tree-node:focus > .el-tree-node__content .iconDept {
    color: #fff !important;
  }

  /*节点失焦时的背景颜色*/
  /deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
    background-color: #399ffb !important;
    color: #fff !important;
  }

  /*节点失焦时的背景颜色*/
  /deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content .iconDept {
    color: #fff !important;
  }
}
</style>
